.headline_container{
    max-width: 250px;
}

.headline_title{
    font-size: xx-small;
    font-weight: bold;
    color: grey;
    text-align: left;
}

.headline_bage{
    border-radius: 15px;
    max-width: 5px;
}

.headline_figure{
    text-align: left;
    font-weight: bold;
    &.large {font-size: 28px;}
    &.medium {font-size: 25px;}
    svg{margin-right: 0 !important;}
}

.headline_unit{
    font-size: xx-small;
    color: grey;
}

.headline_remark{
    font-size: xx-small;
    color: rgb(63, 63, 63);
    text-align: left;
}