.kpi_container{
    box-shadow: rgba(255, 255, 255, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.kpi_bage{
    color: white;
    box-shadow: rgba(255, 255, 255, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    &.mobile_kpi_bage{
        border-radius: 1.5vh 1.5vh 0 0;
    }
}
.kpi_title{
    font-size: larger;
    font-weight: bold;
    text-align: left;
}
.kpi_icon{
    padding: 10px;
    border-radius: 8px;
}

.kpi_figure{
    font-size: 28px;
    font-weight: bold;
    text-align: left;
}

.kpi_prefix, .kpi_suffix{
    font-size: xx-small;
    color: grey;
}

.kpi_remark{
    font-size: xx-small;
    color: rgb(63, 63, 63);
    text-align: left;
}