.top-navbar a{
    font-size: medium;
    font-weight: bold;
}
.top-navbar button{
    font-size: small;
}

.main-left-menu{
    z-index: 10;
    position: fixed;
    width: 65px;
    max-width: 65px;
    height: 100vh;
    background-color: #03173C;
    .icon-image{
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: center;
        user-select: none;
    }
    .chiller-image{
        background-image: url(../../../assets/img/navbar/chiller_grey.png);
        height: 25px;
    }
    .ahu-image{
        background-image: url(../../../assets/img/navbar/ahu_grey.png);
        height: 23px;
    }
    svg {
        color: #79798A;
    }
    .left-menu-item{
        &:hover{
            cursor: pointer;
            .main-image{
                color: #fff;
            }
            .chiller-image{
                background-image: url(../../../assets/img/navbar/chiller_white.png);
            }
            .ahu-image{
                background-image: url(../../../assets/img/navbar/ahu_white.png);
            }
        }
        .page_on{
            &.main-image{
                color: #fff;
            }
            &.chiller-image{
                background-image: url(../../../assets/img/navbar/chiller_white.png);
            }
            &.ahu-image{
                background-image: url(../../../assets/img/navbar/ahu_white.png);
            }
        }
    }
}

