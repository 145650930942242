.admin_layout{
    overflow-y: auto;
    overflow-x: hidden;
    height: 97%;
}

.scroll_list{
    &::-webkit-scrollbar {width: 2vh; min-width: 10px;}
    &::-webkit-scrollbar-thumb {background: #034F84; border-radius: 0;}
    &::-webkit-scrollbar-track {border-radius: 0;background-color: white;}
    &::-webkit-scrollbar-thumb:hover {background: #2d6f9e;}
}

.sub_page{
    // overflow: auto;
    // height: 80vh;
}

.basic_wrap{
    background: rgba($color: #ebe8fd, $alpha: 0.95);
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
    // max-height: 100vh;
}

.config_header{
    font-size: x-large;
    text-align: center;
    padding: 10px 0;
}

.info_wrap{
    // border: 1px solid black;
    padding: 20px 30px;
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.info_main_text{
    font-weight: bold;
    font-size: larger;
}

.info_icon{
    background-color: #034F84;
    display: inline-block;
    padding: 10px;
    border-radius: 100%;
    img{
        max-width: 30px;
    }
}

.my_cate_btn{
    cursor: pointer;
    padding: 10px;
    margin: 5px;
    background-color: #D5EAFF !important;
    color: #034F84;
    border: 4px solid #D5EAFF;
    font-weight: bold !important;
    border-radius: 100%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
    &.on_select{
        background-color: #034F84 !important;
        color: #D5EAFF;
    }
}

.color_container{
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    background-color: #f0eefb;
    border-radius: 5px;
    &.seq_box{
        min-width: 20rem;
        max-width: 20rem;
    }
    .title{font-weight: bold; color: white; padding: 10px 0; font-size: medium;}
    .color_grid{
        background-color: #034F84; min-height: 2rem; border-radius: 5px 5px 0 0;
        &.equip_name{
            background-color: #000000; padding-top: 1rem; padding-bottom: 1rem; 
            .title{
                font-size: large !important;
            }
        }
    }
    .line_grid {max-width: 2rem;}
    .line_grid div{border-left: 1px solid #B4B2BE; height: 100%;}
}

.dd_item{
    display: inline-block;
    background-color: #F8F9FA !important;
    color: black;
    padding: 10px 30px;
    margin-bottom: 5px;
    // border-radius: 100%;
    font-size: small;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -1px 0px inset;
    &.dd_item_sm{ zoom: 0.8; padding: 10px 20px;}
}

.invalid-feedback{
    text-align: left;
}
