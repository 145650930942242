html,body,#root{
  height: 100%;
  overflow: hidden;
}

.App {
  text-align: center;
  background: url("./assets/img/bg/bg_brain.jpg") no-repeat center center fixed;
  background-size: cover;
  // height: 100vh;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dashboard-bg {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 70px;
}

.dashboard-main-content{
  // background: rgba($color: #EEF2F5, $alpha: 0.75);
  background: rgba($color: #0E486D, $alpha: 0.8);
}

.scroll_list, .table-responsive{
  &::-webkit-scrollbar {width: 2vh; min-width: 10px;}
  &::-webkit-scrollbar-thumb {background: #034F84; border-radius: 0;}
  &::-webkit-scrollbar-track {border-radius: 0;background-color: white;}
  &::-webkit-scrollbar-thumb:hover {background: #2d6f9e;}
}

.text-left{
  text-align: left;
}

.text-right{
  text-align: right;
}

.text-center{
  text-align: center;
}

.header_bage {
  background: linear-gradient(to right, rgba(1, 28, 68, 0.8) , rgba(1, 63, 92, 0.5));
  color: white;
}

.app_hospital {
  &.App {
    background: url("./assets/img/bg/bg_network-skylum.jpeg") no-repeat center center fixed;
  }
  .App-header {
    background-color: #28342f;
  }
  .dashboard-bg {
    background: rgba($color: #9aa3a2, $alpha: 0.8);
  }
  .scroll_list, .table-responsive{
    &::-webkit-scrollbar {width: 2vh; min-width: 10px;}
    &::-webkit-scrollbar-thumb {background: #12353f; border-radius: 0;}
    &::-webkit-scrollbar-track {border-radius: 0;background-color: white;}
    &::-webkit-scrollbar-thumb:hover {background: #1a4e4c;}
  }
  .header_bage {
    background: linear-gradient(to right, rgb(12, 54, 49) , rgba(47, 116, 106, 0.6));
  }
}

.mobile_accordion{
  .accordion-item{
    background: none;
  }
}

.small_font{
  font-size: small;
}

table tbody tr{
  vertical-align: middle;
}

.spinner-border{
  user-select: none;
}

// .apexcharts-legend-marker{
//   display: inline-block !important;
// }

// progress bar background color
.progress, .progress-stacked {
  --bs-progress-bg: #d8d6d5 !important;
}