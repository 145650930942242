.zoomed-image-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.zoomed-image {
    max-width: 90vw;
    max-height: 90vh;
}

.sensor-map{
    background: url("./../../../assets/site/mdc/SensorMap-5F.jpeg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 250px;
    height: 100%;
    width: 100%;
    cursor: pointer;
}