.equip_name_title{
    background-color: aliceblue;
    border-radius: 10rem 10rem 0 0;
}

.tabs_sub_tab{
    .nav-item a{
        color: #E9ECEF;
    }

}