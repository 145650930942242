.bg_whole_grey{
  background-color: #FEFEFE;
  &.bg_full{
    height: 100%;
  }
}

.sub_title{
  text-align: left;
  font-weight: bold;
}

.card_title_wrap{
  position: relative;
  .title_text{
    text-align: left;
    font-size: large;
    font-weight: bold;
  }
  .title_remark{
    text-align: left;
    font-size: xx-small;
    color: grey;
  }
  .btn_show_more{
      position: absolute;
      right: 20px;
      bottom: 10px;
      .dropdown-menu-md-start{
        right: 0;
        left: auto;
      }
  }
}

.card_bg {
    background-color: #eee;
  }
  
  .page_header{
    position: relative;
    padding: 1rem 0.5rem;  
    .title_icon{
      display: inline-block;
      user-select: none;
      img{
        user-select: none;
      }
    }
    .title_text{
      font-size: 1.2rem;
      font-weight: bold;
      text-align: left;
      padding: 0.2rem 0.5rem;
      display: inline-block;
    }
    .input_wrap{
      float: right;
    }
    .header_btn_gp{
      float: right;
    }
  }
  
  .card_upper{
    position: relative;
    border-bottom: 1px solid rgba(128, 128, 128, 0.3);
    padding: 1rem 1rem;
  
    .title_text{
      font-size: 1rem;
      font-weight: bold;
      text-align: left;
    }
    .btn_show_more{
        position: absolute;
        right: 0;
        top: 1rem;
        margin-right: 1rem;
    }
  }
  
  .card_content{
    padding: 1rem 1rem;
    .chart_title{
      text-align: center;
      font-weight: bold;
      position: relative;
      top: 0.5rem;
    }
  }
  
  .card_bottom{
    text-align: left;
    font-size: 0.5rem;
    border-top: 1px solid rgba(128, 128, 128, 0.3);
    padding: 0.5rem 1rem;
  }


  // fix chart css problem
.apexcharts-tooltip-series-group {
    max-height: 23px;
}
.apexcharts-legend {
  display: flex;
  overflow: auto;
  padding: 0 10px;
}
.apexcharts-legend.apx-legend-position-bottom, .apexcharts-legend.apx-legend-position-top {
  flex-wrap: wrap
}
.apexcharts-legend.apx-legend-position-right, .apexcharts-legend.apx-legend-position-left {
  flex-direction: column;
  bottom: 0;
}
.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-left, .apexcharts-legend.apx-legend-position-top.apexcharts-align-left, .apexcharts-legend.apx-legend-position-right, .apexcharts-legend.apx-legend-position-left {
  justify-content: flex-start;
}
.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center, .apexcharts-legend.apx-legend-position-top.apexcharts-align-center {
  justify-content: center;
}
.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-right, .apexcharts-legend.apx-legend-position-top.apexcharts-align-right {
  justify-content: flex-end;
}
.apexcharts-legend-series {
  cursor: pointer;
  line-height: normal;
}
// .apexcharts-legend.apx-legend-position-bottom .apexcharts-legend-series, .apexcharts-legend.apx-legend-position-top .apexcharts-legend-series{
//   display: flex;
//   align-items: center;
// }
.apexcharts-legend-text {
  position: relative;
  font-size: 14px;
}
.apexcharts-legend-text *, .apexcharts-legend-marker * {
  pointer-events: none;
}
.apexcharts-legend-marker {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-right: 3px;
  margin-top: 0px;
  // border-style: solid;
}

//   .apexcharts-legend.apexcharts-align-right .apexcharts-legend-series, .apexcharts-legend.apexcharts-align-left .apexcharts-legend-series{
//     display: inline-block;
//   }
.apexcharts-legend-series.apexcharts-no-click {
  cursor: auto;
}
.apexcharts-legend .apexcharts-hidden-zero-series, .apexcharts-legend .apexcharts-hidden-null-series {
  display: none !important;
}
.apexcharts-inactive-legend {
  opacity: 0.45;
}