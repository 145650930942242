.opt_bubble {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding: 10px;
    background: green;
    color: white;
    text-align: center;
}

.line_chart_wrap{
    height: 240px;
    &.ap_chart{ height: 155px; }
}

.line_chart_wrap_advance{
    height: 500px;
    padding-bottom: 130px;
}

.energy_kpi_chart_wrap{
}

.spinner-border {
    animation-duration: 1s;
}

.date_range_input{
    margin-left: calc(var(--bs-border-width) * -1);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    min-width: 200px;
    font-size: 14px;
    padding-left: 5px;
}

#opt_ctrl_history{
    min-width: 80%;
}

.container_tab{
    .nav-tabs .nav-link{
        color: #000;
        font-weight: bold;
        &.active{
            background-image: linear-gradient(to bottom, #f1f2f3, #fff);
        }
    }
}

.equip_btn_wrap{
    justify-content: center;

    .equip_btn{
        height: 100%;
        margin-right: 0.3rem;
        padding: 0.2rem 0.2rem;
        background-color:#031B41;
        border-radius: 5px;
        opacity: 70%;
        color: #fff;
        cursor: pointer;
        user-select: none;
        text-align: center;
        &:hover{
            opacity: 100%;
        }
        &.selected{
            opacity: 100%;
            color: #fff;
        }
        .icon-image{
            background-size: contain;
            background-repeat: no-repeat;
            background-position-x: center;
            user-select: none;
        }
        .ahu-image{
            background-image: url(../../assets/img/navbar/ahu_white.png);
        }
    }
}

.calendar_btn{
    display: inline-block;
    background-color: #0D6EFD;
    padding: 0.5rem 0.7rem;
    border-radius: 0.5rem;
}

.ai_ccms{
    background-image: linear-gradient(to right, #198754, #FFC107, #FFC107) !important;
}

.control_chart_container{
    background-color: #f7f7f7;
}

.menu_verticle{
    background-color: yellow;
    position: relative;
    left: 0;
    z-index: 3;
    width: 20px;
}