.map_container{
  overflow: hidden;
}

.map_hk {
  background: url("../../../assets/img/map/B50K_R200index-geo_1080.png");
  background-position: center center;
  background-size: auto;
  background-attachment: local;
  background-repeat: no-repeat;
  height: 820px;
  opacity: 0.6;
}

.map_hk_mobile {
  background: url("../../../assets/img/map/B50K_R200index-geo_1080.png");
  background-position: center center;
  background-size: auto;
  background-attachment: local;
  background-repeat: no-repeat;
  height: 85vh;
  opacity: 0.6;
}

.location_building{
  position: relative;
  z-index: 1;

  &.location_mdc{
    top: 610px;
    left: 105px
  }

  .location_dot{
    position: absolute;
    z-index: 2;
    color: #daebe8;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  }

  .location_label{
    background-color: #daebe8;
    color: black;
    font-weight: bold;
    padding: 5px 30px;
    margin-left: 10px;
    top: 30px;
    display: inline;
    position: absolute;
    border-radius: 30px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  }
}

.location_building_mobile{
  z-index: 1;
  padding: 30px;
  position: absolute;
  height: 100vh;

  .location_label{
    background-color: #daebe8;
    color: black;
    font-weight: bold;
    padding: 20px 30px;
    margin-left: 0px;
    border-radius: 30px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  }
}