.control_container{
    background-color: #f0f0f0;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
}

.control_title{
    font-size: xx-small;
    font-weight: bold;
    color: #3d3d3d;
    text-align: center;
}

.control_bage{
    border-radius: 15px;
    max-width: 5px;
}

.control_figure{
    font-size: 28px;
    text-align: center;
    font-weight: bold;
    &.smaller_font{
        font-size: 22px;
    }
}

.control_unit{
    font-size: xx-small;
    color: grey;
}

.control_remark{
    font-size: xx-small;
    color: rgb(63, 63, 63);
    text-align: center;
}