.login_form {
    background: radial-gradient(ellipse at top, #92a8d1, transparent), radial-gradient(ellipse at bottom, #a2b9bc, transparent);
    padding-top: 5%;
    padding-bottom: 5%;
}

.emsd_logo {
    width: 50%;
    min-width: 100px;
    max-width: 150px;
}

.login_text{
    font-family: sans-serif;
}